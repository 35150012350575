import { reactive } from "@vue/composition-api";
 
export const angelFormState =  reactive( {data:{
    isUserDetailsDisplayed: false,
    isUserEdit: false,
    inputFields: {
        newLicenseAmount: 0,
        name: "",
        password: "",
        showChangeBroker: false,
        NewPassword: "",
        ConfirmPassword: "",
        NewLicenseExtendedDate: "",
        userDetails: {
          email: "",
          phone: "",
          broker: {},
          license: {},
          licenceArr: [],
          NumberOfLicense: 0,
          amount: 0,
          showChangedPassword: false,
          SubscriptionExpiryDate: "",
          newLicenseSelected: null,
          existingLicense: [],
          stags: [],
        },
        email: "",
        phone: "",
        isShowing: false,
        isLoginByOtp: false,
        license: "PaperTrading",
        amount: 0,
        otp: null,
      },

}} )