<template>
  <div>
    <div>
      <div class="angelFormContainer" v-if="!inputFields.pageloader">
        <div v-if="!angelFormState.data.isUserDetailsDisplayed && !angelFormState.data.isUserEdit">
                        <UserAccountInformation />

         
            
          <!-- </form> -->
        </div>
        <div
          v-if="angelFormState.data.isUserDetailsDisplayed && userDetails.data"
          class="userDetailsDisplayContainer userDetailsDisplayContainerSpace"
        >
          <div class="EditheadingContainer">
            <h3>
              Hello 
              <span
                style="text-transform: capitalize !important;"
              >
                {{ angelApiData.name.toLowerCase() }}
              </span>
            </h3>
          </div>
          <div>
            <UserAccountInformation />

            <!-- <button
              class="fetch_button"
              @click="
                isUserEdit = true;
                isUserDetailsDisplayed = false;
              "
            >
              Edit / Renew
            </button> -->
          </div>
        </div>
        <div
          v-if="angelFormState.data.isUserEdit"
          class="userDetailsEditContainer userDetailsDisplayContainerSpace"
        >
          <div class="UserEditHeadingContainer">
            <h3>Manage Information</h3>
          </div>
          <UserAccountEditForm :AUTH_DETAILS="AUTH_DETAILS" />
          <hr />
        </div>
        <div class="">
          <div class="policyLinks">
            <!-- ckncsdkcn -->
            <a
              href="https://algoji-my.sharepoint.com/:u:/p/saksham/ER65uEAlzAxEu48e9VNGG6UBcWSWKJDnOuzcWW9V8E781Q?e=d3HPpp"
              class="policyLink"
            >
              Download Apibridge</a
            >
            <router-link class="policyLink" to="/usage-policy"
              >Acceptabe Use Policy</router-link
            >
            <router-link class="policyLink" to="/refund-policy"
              >Refund Policy</router-link
            >
            <router-link class="policyLink" to="/privacy-policy"
              >Privacy Policy</router-link
            >
            <!-- <router-link to="/refund-policy">cschdchd</router-link> -->
          </div>
          <div class="copyRightContainer">
            <span class="copyRightText"
              >Copyright © 2021 Algoji Enterprises Pvt Ltd. All rights
              reserved.</span
            >
          </div>
        </div>
      </div>
      <div v-if="inputFields.pageloader">
        <PageLoader />
      </div>
    </div>
  </div>
</template>

<script>
// import "../assets/css/Common.css";
import "../assets/css/RegisterFormStyle.css";
import { dynamicFormData } from "../state/dynamicFormData.js";
import { userDetails } from "../state/userDetails.js";
import { URLS } from "../state/URLS.js";
import { angelFormState } from "../state/angelFormState.js";
console.log(angelFormState)
debugger;

// import UserAccountInformation from "../components/UserAccountInformation.vue";
// import UserAccountEditForm from "../components/UserAccountEditForm.vue";
import PageLoader from "../components/PageLoader.vue";
export default {
  name: "AngelRegistration",
  components: {
    UserAccountInformation: () =>
      import("../components/AngelUserAccountInformation"),
    PageLoader,
    UserAccountEditForm: () => import("../components/AngelUserAccountEditForm"),
  },
  data() {
    return {
      angelKeys: {
        // apiKeys: "BN6lfsqo",
        apiKeys: "9EzIsjAR",
        authKeys: "",
        refreshToken: "",
      },
      isRedirectedFromAngel: false,
      dynamicFormData,
      userDetails,
      URLS,
      api_base_url: process.env.VUE_APP_API_URL,
      demo_api_base_url: process.env.VUE_APP_DEMO_API_URL,
      admin_base_url: process.env.VUE_APP_ADMIN_API_URL,
      proxyUrl: "",
      formDetails: {
        formName: "angelb",
        isFormLoaded: false,
        isFormLoadedError: false,
        formLoadedErrorText: "",
        formTitle: "",
      },
      AUTH_DETAILS: {
        ADMIN_API_TOKEN: "",
        Admin_id: "",
        API_TOKEN: "",
        USER_TOKEN: "",
      },
      validation: {
        isUserExist: false,
        isNameError: false,
        isPasswordError: false,
        isEmailError: false,
        isPhoneError: false,
        isOtpError: false,
      },
      otpToken: "",
      isOtpVerified: false,
      isOtpSend: false,
      showChangedPassword: false,
      ...angelFormState.data,
      angelFormState,
      isAuthFormDisable: false,
      angelApiData: null,
      inputFields: {
        name: "", // CHANGE CODE
        // name: "A331890", // CHANGE CODE
        password: "", // CHANGE CODE
        userDetails: {
          email: "",
          phone: "",
          broker: {},
          license: {},
          licenceArr: [],
          NumberOfLicense: 0,
          amount: 0,
          SubscriptionExpiryDate: "",
          newLicenseSelected: null,
          existingLicense: [],
          stags: [],
        },
        pageloader: false,
        email: "",
        phone: "",
        isShowing: false,
        isLoginByPassword: false, // CHANGE CODE
        // isLoginByPassword: true,// CHANGE CODE
        license: "PaperTrading",
        amount: 0,
        otp: "",
      },
      isAngelLoginSuccessFull: false,
      angelLoginResp: null,
    };
  },
  async mounted() {
    // this.formDetails.formName = "algoji";
    if (
      (!!this.$route.query.auth_token &&
        this.$route.query.auth_token != null) ||
      (!!this.$route.query.jwtToken && this.$route.query.jwtToken != null)
    ) {
      this.inputFields.pageloader = true;
      this.isRedirectedFromAngel = true;
      this.dynamicFormData.data = await this.fetchFormData();
      this.setFormData(this.dynamicFormData.data);
      // getUserProfile
      this.angelKeys.authKeys =
        this.$route.query.auth_token ?? this.$route.query.jwtToken;
      this.angelKeys.authKeys = `Bearer ${this.angelKeys.authKeys}`;

      // await this.getAngelProfile();
      await this.authenticateFormSubmit();
      this.inputFields.pageloader = false;
    } else {
      this.dynamicFormData.data = await this.fetchFormData();
      this.setFormData(this.dynamicFormData.data);
    }

    // document.title = "apibridge.app"
    // await this.authenticateFormSubmit();
    // this.isUserEdit= true;
    // this.isUserDetailsDisplayed= false;
    // await this.fetchFormData();
  },

  methods: {
    async getAngelProfile() {
      const fetchAngelUserDetailsUrl = `https://apiconnect.angelbroking.com/rest/secure/angelbroking/user/v1/getProfile`;
      const myHeaders = new Headers();
      myHeaders.append("Authorization", this.angelKeys.authKeys);
      myHeaders.append("X-UserType", "USER");
      myHeaders.append("X-SourceID", "WEB");
      myHeaders.append("X-ClientLocalIP", "CLIENT_LOCAL_IP");
      myHeaders.append("X-ClientPublicIP", "CLIENT_PUBLIC_IP");
      myHeaders.append("X-MACAddress", "dfwdcfw");
      myHeaders.append("Accept", "application/json");
      myHeaders.append("X-PrivateKey", this.angelKeys.apiKeys);
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      const resp = await fetch(fetchAngelUserDetailsUrl, requestOptions);
      const data = await resp.json();
      if (data.status) {
        this.angelApiData = data.data;
        this.inputFields.name = data.data.clientcode;
        this.userDetails.data._Name = data.data.clientcode;
      }
      return !!data.status;
      // debugger
    },
    redirectToAngel() {
      window.location.href = `https://smartapi.angelbroking.com/publisher-login?api_key=${this.angelKeys.apiKeys}`;
    },
    async updateRecordsAngel() {
      // planName = newLicenseSelected.number_of_license == 1
      let myCurrentDate = new Date();
      let myFutureDate = new Date(myCurrentDate);
      myFutureDate.setDate(myFutureDate.getDate() + 30);

      const payload = {
        partnerUserID: "saurabh@algoji.com",
        ClientCode: this.userDetails.data._Name,
        subscriptionDate: new Date().toISOString(),
        planName: "Learner",
        validity: 1,
        expiryDate: myFutureDate.toISOString(),
        subscriptionAmount: 0,
        paymentID: "",
        productName: "APIBridge",
      };
      // console.log({payload})
      // return ;
      let myHeaders = new Headers();
      myHeaders.append("X-ClientLocalIP", "192.168.0.0");
      myHeaders.append("X-ClientPublicIP", "268.98.11.223");
      myHeaders.append("X-MACaddress", "00:25:96:FF:FE:12:34:56");
      myHeaders.append("X-UserType", "Partner");
      myHeaders.append("content-type", "application/json");
      myHeaders.append("X-Password", "@lgoji455");

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: "follow",
      };
      fetch(
        "https://smartstoreapi.angelbroking.com/rest/secure/angelbroking/playstore/go/partner/v1/pushPartnerData",
        requestOptions
      );
    },
    nameChange() {
      this.isOtpSend = this.isOtpVerified = false;
    },
    async checkUserNameExist() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", this.AUTH_DETAILS.ADMIN_API_TOKEN);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow",
      };

      const resp = await fetch(
        `${this.admin_base_url}/Admin/profile?Username=${this.inputFields.name}`,
        requestOptions
      );
      const body = await resp.json();
      if (body.message == "success") {
        this.userDetails.data = body.data;
        this.userDetails.data._Name = this.inputFields.name;
        debugger;
        this.userDetails.data.licenceArr = this.dynamicFormData.data.LicenseList;
      }
      return body.message == "success";
      /*
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `${this.AUTH_DETAILS.ADMIN_API_TOKEN}`
      );


      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        // body: raw,
        redirect: "follow",
      };
      // try{
        const response = await  fetch(
        `${this.api_base_url}/Admin/profile?Username=${this.inputFields.name}`,
        requestOptions
      )

      const body = await response.json()
      this.userData = body;
      return body.message  == "success"
      // }catch(er){
        debugger
      // }
*/
    },

    async createUserIfDoesntExist() {
      let myCurrentDate = new Date();
      let myFutureDate = new Date(myCurrentDate);
      myFutureDate.setDate(myFutureDate.getDate() + 30);
      const createUserUrl = `${this.admin_base_url}/Admin/registerUser`;
      const Header = new Headers();
      Header.append("Authorization", `${this.AUTH_DETAILS.ADMIN_API_TOKEN}`);
      const body = JSON.stringify({
        AdminID: 27,
        AdminBrokerID: 908,
        Name: this.inputFields.name,
        Username: this.inputFields.name,
        Password: this.inputFields.name,
        Mobile: "9876543210",
        Email: "angeluser@test.com",
        SubscriptionExpiryDate: myFutureDate.toISOString(),
        LastLogin: new Date().toISOString(),
        DateCreated: new Date().toISOString(),
        Active: true,
        IsLoggedIn: true,
        Broadcast: true,
        ModuleID: 1,
        NumberOfLicense: 1,
        Features: null,
        Port: 30001,
        BrokerBaseURI: "",
      });

      const resp = await fetch(createUserUrl, {
        method: "POST",
        body,
        headers: Header,
      });
      const respBody = await resp.json();
      return (respBody.message = "User Added");
    },
    async fetchFormData() {
      const fetchFormDataUrl = `${this.proxyUrl}${this.api_base_url}api/forms/Template/${this.formDetails.formName}`;

      this.formDetails.isFormLoaded = false;

      try {
        const formDataResp = await fetch(fetchFormDataUrl, {
          method: "GET",
          redirect: "follow",
        });

        const reqStatusCode = formDataResp.status;

        const formDataObj = await formDataResp.json();

        const formDataValue = formDataObj.data; //Need to check whetheer form data

        this.formDetails.isFormLoaded = true;
        if (reqStatusCode != 200) {
          this.formDetails.isFormLoadedError = true;
          this.formDetails.formLoadedErrorText = "Something Went Wrong";
          return false;
        }
        if (formDataObj.code != 200) {
          this.formDetails.isFormLoadedError = true;
          this.formDetails.formLoadedErrorText = "Form Not Found";
          return false;
        }
        if (formDataObj.error) {
          this.formDetails.isFormLoadedError = true;
        }

        this.formDetails.isFormLoaded = true;
        this.formDetails.isFormLoadedError = false;
        return formDataValue;
      } catch (e) {
        this.formDetails.isFormLoaded = true;
        this.formDetails.isFormLoadedError = true;
        this.formDetails.formLoadedErrorText = "Something Went Wrong";
        return false;
      }
    },
    async getUserStags() {
      const getStageUrl = `${this.proxyUrl}${this.URLS.admin_base_url}/Admin/userFeatures?UserID=${this.userDetails.data.ID}`;
      const getStageHeader = new Headers();
      getStageHeader.append(
        "Authorization",
        `${this.AUTH_DETAILS.ADMIN_API_TOKEN}`
      );

      const requestObj = {
        method: "GET",
        headers: getStageHeader,
      };
      const getStageResp = await fetch(getStageUrl, requestObj);
      const { data } = await getStageResp.json();
      this.inputFields.userDetails.stags = data;
      return data;
    },
    getJsonHeaders() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      return myHeaders;
    },

    async isUserExistInAngel() {
      var myHeaders = new Headers();
      myHeaders.append("X-PrivateKey", `${this.angelKeys.apiKeys}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "application/json");
      myHeaders.append("X-UserType", "USER");
      myHeaders.append("X-SourceID", "WEB");
      myHeaders.append("X-ClientLocalIP", "CLIENT_LOCAL_IP");
      myHeaders.append("X-ClientPublicIP", "CLIENT_PUBLIC_IP");
      myHeaders.append("X-MACAddress", "MAC_ADDRESS");

      var raw = JSON.stringify({
        clientcode: this.inputFields.name,
        password: this.inputFields.password,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const response = await fetch(
        "https://apiconnect.angelbroking.com/rest/auth/angelbroking/user/v1/loginByPassword",
        requestOptions
      );
      const body = await response.json();
      this.angelLoginResp = body.data;
      this.isAngelLoginSuccessFull = body.status;
    },

    getJsonHeader() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      return myHeaders;
    },
    async getAllLicence() {
      this.userDetails.data.licenceArr = [];
      const getAllLicenceUrl = `${this.proxyUrl}${this.api_base_url}api/forms/FetchLicenseList`;
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append(
        "Authorization",
        // this.AUTH_DETAILS.ADMI   N_API_TOKEN
        `Bearer ${this.userDetails.data.Token}`
      );
      const reqObj = {
        headers: jsonHeader,
        method: "GET",
        redirect: "follow",
      };
      const getLicenceResp = await fetch(getAllLicenceUrl, reqObj);
      const getLicenceJson = await getLicenceResp.json();

      const licenceArr = getLicenceJson.data;

      this.userDetails.data.licenceArr = licenceArr;
      if (this.userDetails?.data?.licenceArr?.length) {
        this.inputFields.userDetails.license = this.userDetails.data.licenceArr[0];
      }
    },
    async authenticateFormSubmit() {
      const isLoginSuccessfull = await this.getAngelProfile();
      if (!isLoginSuccessfull) {
        this.isRedirectedFromAngel = false;

        return this.$swal({
          title: "Error",
          text: "User Doesnt exist",
          icon: "error",
          backdrop: true,
          allowOutsideClick: false,
        });
      }
      // const isLoginByPassword = this.inputFields.isLoginByPassword;

      const isUserExist = await this.authenticateWithPassword();
      if (isUserExist) {
        this.inputFields.pageloader = true;

        this.$swal({
          title: "Success",
          text: "Login Successfull",
          icon: "success",
          backdrop: true,
          allowOutsideClick: false,
        });

        await this.displayUserDetails();
        this.angelFormState.data.isUserDetailsDisplayed = true;
        this.inputFields.pageloader = false;
        this.isAuthFormDisable = true;
      } else {
        //Create User
        // alert("Please Enter correct Details");

        const isUserCreated = await this.createUserIfDoesntExist();
        if (isUserCreated) {
          this.$swal({
            title: "Success",
            text:
              "New user successfully created, Click here to download and login with APIBridge.",
            icon: "success",
            backdrop: true,
            allowOutsideClick: false,
          });

          this.inputFields.password = this.inputFields.name;
          this.updateRecordsAngel();
          this.authenticateFormSubmit();
        }
        return;
      }

      // Auth With Password
      // const isLoginSuccessfull = await this.authenticateWithPassword();
      // if (isLoginSuccessfull) {
      //   // alert("Login Successfull");

      //   this.$swal({
      //     title: "Success",
      //     text: "Login Successfull",
      //     icon: "success",
      //     backdrop: true,
      //     allowOutsideClick: false,
      //   });

      //   this.inputFields.pageloader = true;
      //   // this.userDetails.data = await this.fethUserDetailByUserName();
      //   await this.displayUserDetails();
      //   this.isUserDetailsDisplayed = true;
      //   this.inputFields.pageloader = false;
      //   this.isAuthFormDisable = true;
      // } else {
      //   alert("Please Enter correct Details");
      //   return;
      // }
    },
    async authenticateWithPassword() {
      // if (this.isUserNameValid && this.isPasswordValid) {
      const checkUsernamPasswordUrl = `${this.proxyUrl}${this.URLS.admin_base_url}/Login/SubsUser`;
      const jsonHeader = this.getJsonHeader();
      // jsonHeader.append("")
      const checkUsernamPasswordRequestObj = {
        method: "POST",
        headers: jsonHeader,
        redirect: "follow",
        body: JSON.stringify({
          Username: this.inputFields.name,
          Password: this.inputFields.name,
        }),
      };
      try {
        const authResp = await fetch(
          checkUsernamPasswordUrl,
          checkUsernamPasswordRequestObj
        );

        const statusCode = authResp.status;
        if (statusCode == 401) {
          return false;
        }

        const userData = await authResp.json();
        // const userData = authObj.data;
        this.AUTH_DETAILS.USER_TOKEN = userData.Token;
        if (userData.Token) {
          this.userDetails.data = userData;
          this.userDetails.data._Name = this.inputFields.name;

          debugger;
          return true;
        }
      } catch (e) {}
      // }
    },
    async fetchCurrentBroker() {
      if (this.userDetails.data.AdminBrokerID) {
        const fetch_Current_Broker_Url = `https://emailclient.conveyor.cloud/api/Utils/FetchBrokerByBrokerId/`;
        const jsonHeader = this.getJsonHeaders();
        const requestObject = {
          method: "POST",
          headers: jsonHeader,
          body: JSON.stringify({
            brokerId: this.userDetails.data.AdminBrokerID,
          }),
        };
        const currentBrokerResp = await fetch(
          fetch_Current_Broker_Url,
          requestObject
        );
        const currentBrokerRespJson = await currentBrokerResp.json();
        if (currentBrokerRespJson.code == 200) {
          this.userDetails.data.selectedBroker = JSON.parse(
            currentBrokerRespJson.data
          );
        }
      }
    },
    async displayUserDetails() {
      // console.log(this.userDetails.data)
      this.inputFields.userDetails.email = this.userDetails.data.Email;
      this.inputFields.userDetails.phone = this.userDetails.data.dataMobile;
      this.inputFields.userDetails.NumberOfLicense = this.userDetails.data.NumberOfLicense;
      const expiryDate = this.userDetails.data.ExpiryDate;
      this.inputFields.userDetails.SubscriptionExpiryDate = this.userDetails.data.ExpiryDate = this.userDetails.data.ExpiryDate.split(
        "-"
      )
        .reverse()
        .join("-");

      // this.inputFields.userDetails.SubscriptionExpiryDate = new Date(
      //   this.userDetails.data.ExpiryDate
      // ).toLocaleDateString("en-Ca");

      // this.userDetails.data.ExpiryDate = this.userDetails.data.ExpiryDate
      // this.userDetails.data.ExpiryDate = new Date(
      //   this.userDetails.data.ExpiryDate
      // ).toLocaleDateString("en-Ca");
      this.inputFields.userDetails.license.ModuleID = this.userDetails.data.ModuleID = this.userDetails.data.MyModule.split(
        "M"
      )[1];
      if (this.dynamicFormData?.data?.BrokerList?.length) {
        if (this.dynamicFormData?.data?.BrokerList?.length > 1) {
          this.inputFields.userDetails.broker = this.dynamicFormData.data.BrokerList.filter(
            (broker) =>
              broker.BrokerValue == this.userDetails.data.AdminBrokerID
          )[0];
        } else {
          this.inputFields.userDetails.broker = this.dynamicFormData.data.BrokerList[0];
        }
      }

      // await this.fetchCurrentBroker();
      //
      await this.getAllLicence();

      if (this.userDetails.data.licenceArr?.length) {
        const currentLicense = this.userDetails.data.licenceArr.filter(
          (license) => this.userDetails.data.ModuleID == license.module_type
        )[0];
        //
        this.userDetails.data.currentLicense = currentLicense;
      }
      // this.inputFields.displayUserDetails
      // this.getUserStags();
      // this.inputFields.userDetails.broker =
      // this.inputFields.userDetails.expiraryDate =
      // this.inputFields.userDetails.amount =
    },

    async _displayUserDetails() {
      console.log({ userDetails: this.userDetails });
      console.log({ dynamicFormData: this.dynamicFormData });
      this.inputFields.userDetails.email = this.userDetails.data.Email;
      this.inputFields.userDetails.phone = this.userDetails.data.dataMobile;
      this.inputFields.userDetails.NumberOfLicense = this.userDetails.data.NumberOfLicense;
      const expiryDate = this.userDetails.data.ExpiryDate;
      // this.inputFields.userDetails.SubscriptionExpiryDate =
      //   this.userDetails.data.ExpiryDate
      // =
      //   this.userDetails.data.ExpiryDate.split("-").reverse().join("-");
      this.userDetails.data.ExpiryDate = this.userDetails.data.SubscriptionExpiryDate;
      // console.log({exp: this.userDetails.data.ExpiryDate })
      this.userDetails.data.ExpiryDate = new Date(
        this.userDetails.data.ExpiryDate
      ).toLocaleDateString("en-Ca");

      // this.userDetails.data.ExpiryDate = this.userDetails.data.ExpiryDate
      // this.userDetails.data.ExpiryDate = new Date(
      //   this.userDetails.data.ExpiryDate
      // ).toLocaleDateString("en-Ca");
      this.inputFields.userDetails.license.ModuleID = this.userDetails.data.ModuleID;
      // =
      //   this.userDetails.data.MyModule.split("M")[1];
      if (this.dynamicFormData?.data?.BrokerList?.length) {
        if (this.dynamicFormData?.data?.BrokerList?.length > 1) {
          this.inputFields.userDetails.broker = this.dynamicFormData.data.BrokerList.filter(
            (broker) =>
              broker.BrokerValue == this.userDetails.data.AdminBrokerID
          )[0];
        } else {
          this.inputFields.userDetails.broker = this.dynamicFormData.data.BrokerList[0];
        }
      }

      // await this.fetchCurrentBroker();
      //
      // await this.getAllLicence();

      if (this.dynamicFormData.data.LicenseList?.length) {
        const currentLicense = this.dynamicFormData.data.LicenseList.filter(
          (license) => this.userDetails.data.ModuleID == license.module_type
        )[0];
        //
        this.userDetails.data.currentLicense = currentLicense;
      }
      // this.inputFields.displayUserDetails
      // this.getUserStags();
      // this.inputFields.userDetails.broker =
      // this.inputFields.userDetails.expiraryDate =
      // this.inputFields.userDetails.amount =
    },
    async sendEmailOtp() {
      const userName = this.inputFields.name;
      const fetchUserEmailUrl = `${this.proxyUrl}${this.URLS.api_base_url}api/login/SendAuthOTP?username=${userName}`;

      const getEmailOtpResp = await fetch(fetchUserEmailUrl);
      const emailOtpObj = await getEmailOtpResp.json();

      this.isOtpSend = emailOtpObj.OtpToken != "";
      if (this.isOtpSend) {
        this.otpToken = emailOtpObj.OtpToken;
        this.isOtpSend = true;
      }
    },
    async getUserEmailAndSendOtp() {
      //fetch email
      this.isOtpSend = false;
      this.isOtpVerified = false;
      if (this.isUserNameValid) {
        this.sendEmailOtp();
        // this.userDetails.data = await this.fethUserDetailByUserName();
        //
        // if (this.userDetails.data) {
        //   this.emailOtpSend();
        // } else {
        //   alert("Please enter correct username");
        //   return false;
        // }
      }
      //send otp
      //verify otp
    },
    async emailOtpSend() {
      this.isOtpSend = false;
      this.isOtpVerified = false;
      //
      if (this.userDetails.data.Email) {
        const payload = JSON.stringify({
          Name: this.userDetails.data.Name,
          Email: this.userDetails.data.Email,
          isLicenseAuth: true,
        });
        //
        const otpSendUrl = `${this.proxyUrl}${this.api_base_url}api/forms/RequestOTP`;
        //
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", this.AUTH_DETAILS.API_TOKEN);

        const resp = await fetch(otpSendUrl, {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: payload,
        });
        const respObj = await resp.json();
        this.isOtpSend = respObj.code == 200 && respObj.data != "";
        if (this.isOtpSend) {
          this.otpToken = respObj.data;
        }
      } else {
        //Display Error
      }
    },
    async verifyOtp() {
      this.isOtpVerified = false;
      const isNameValid = this.isUserNameValid;
      if (isNameValid && this.inputFields.otp) {
        const verifyOTPUrl = `${this.proxyUrl}${this.api_base_url}api/login/GetValidUserDetails?OTP=${this.inputFields.otp}`;

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${this.otpToken}`);
        headers.append("Content-Type", "application/json");

        const body = JSON.stringify({
          OTP: this.inputFields.otp,
        });
        const requestObject = {
          redirect: "follow",
          headers,
          method: "GET",
        };
        const verifyResp = await fetch(verifyOTPUrl, requestObject);

        const verifyRespObj = await verifyResp.json();
        const isOtpVerified = (this.isOtpVerified = verifyRespObj.UserId != "");
        if (isOtpVerified) {
          this.userDetails.data = verifyRespObj;
        }
        return isOtpVerified;
      }
    },
    async fethUserDetailByUserName() {
      return;
      const fetchUserDetailsByUserName = `${this.proxyUrl}${this.URLS.admin_base_url}/Admin/profile?Username=${this.inputFields.name}`.trim();
      const jsonHeader = this.getJsonHeader();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.ADMIN_API_TOKEN);
      const requestObj = {
        method: "GET",
        headers: jsonHeader,
        redirect: "follow",
      };

      const userDetailsResp = await fetch(
        fetchUserDetailsByUserName,
        requestObj
      );
      const userDetailsRespInJson = await userDetailsResp.json();
      const userDetails = userDetailsRespInJson.data;

      return userDetails;
    },
    setFormData(formData) {
      this.AUTH_DETAILS.API_TOKEN = "Bearer " + formData.Token;
      this.AUTH_DETAILS.ADMIN_API_TOKEN = "Bearer " + formData.Admin.Token;
      // this.AUTH_DETAILS.ADMIN_API_TOKEN = this.AUTH_DETAILS.API_TOKEN ;

      this.AUTH_DETAILS.Admin_id = formData.Admin.AdminID;
    },
  },
  computed: {
    isUserNameValid() {
      const username = this.inputFields.name;
      if (username != undefined && username != null) {
        return username.trim() != "";
      }

      return false;
    },
    isPasswordValid() {
      const password = this.inputFields.password;
      if (password != undefined && password != null) {
        return password.trim() != "";
      }

      return false;
    },

    getExistingLicense() {
      // console.log(this.userDetails.data.ModuleID)

      return this.userDetails.data.licenceArr.filter(
        (license) => this.userDetails.data.ModuleID == license.module_type
      );
    },
    getBrokerSelected() {
      return this.dynamicFormData.data.BrokerList.filter(
        (broker) => broker.BrokerValue == this.userDetails.data.AdminBrokerID
      )[0];
    },
    getNewLicenseExtendedDate() {
      const newLicenseSelected = this.inputFields.userDetails
        .newLicenseSelected;
      if (newLicenseSelected != null && newLicenseSelected != "null") {
        let startDate = new Date();
        const lastLicenseDate = new Date(
          this.userDetails.data.SubscriptionExpiryDate
        );
        lastLicenseDate.getTime() > startDate.getTime()
          ? (startDate = lastLicenseDate)
          : "";
        const numberOfNewLicense = newLicenseSelected.number_of_license;

        startDate.setDate(startDate.getDate() + 30 * numberOfNewLicense);
        return startDate.toLocaleDateString("en-CA");
      } else {
        return new Date(
          this.userDetails.data.SubscriptionExpiryDate
        ).toLocaleDateString("en-CA");
      }
    },
  },
};
</script>

<style>
body{
  background-color: #fff !important;
  padding: 0px 40px !important;
}
.angelFormContainer {
  display: flex;
  min-height: 100vh !important;
  max-height: 100vh !important;
  flex-direction: column;
  justify-content: space-between;
}

.hideContainer {
  display: none;
}
.fetch_button {
  background: rgb(92, 148, 189);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgb(255, 255, 255);
  font-family: "ProximaNova Regular", sans-serif;
  padding: 8px 67px;
  cursor: pointer;
}
</style>
<style scoped>


html{
  background-color: #fff !important;
}
.copyRightContainer {
  display: flex;
  justify-content: center;
}
.copyRightContainer {
  font-size: 0.75rem;
  cursor: pointer;
}
.copyRightContainer :hover {
  text-decoration: underline;
}
@media (max-width: 400px) {
  .copyRightContainer {
    /* font-size: 0.7rem; */
    padding: 10px 2px;
  }
}
.linkStyleNone {
  text-decoration: none;
}
.row {
  flex-wrap: wrap;
  align-items: center;
  justify-items: flex-start;
}
.userDetailsDisplayContainerSpace {
  /* max-resolution: ; */
  /* width:100%; */
  margin: 20px;
  background: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
}
.loginContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.sendOtpBtn {
  margin-bottom: 1rem;
}
body,
html {
  display: block;
  box-sizing: border-box;
}
body {
  width: 100vw;
  padding: 0;
  margin: 0;
}
.input {
  min-width: 275px;
  overflow: hidden;
  border-radius: 25px;
  height: 35px;
  /* text-align: center; */
  margin-bottom: 20px;
  outline: none;
  border: 1;
  font-size: 16px;
  padding: 2px 20px;
  border: 1px solid grey;
}
.policyLinks {
  margin-top: 8rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  color: black;
}
.loginBtn {
  border-radius: 25px;
  height: 50px;
  text-align: center;
  display: block;
  padding: 0 20px;
  min-width: 125px;
  background-color: white;
  font-size: 18px;
  cursor: pointer;
  /* margin-right: 40px; */
  outline: none;
  border: 1px solid grey;
}
.rememberMe {
  padding-top: 5px;
  padding-bottom: 35px;
}

* {
  font-family: "Samim", sans-serif;
}
.logo {
  height: 135px;
}
.main {
  margin-top: 5rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  text-align: center;
}
.policyLink {
  color: black;
  font-weight: 500;
  margin-left: 15px;
}
textarea:focus,
input:focus,
button:focus {
  outline: none;
}
a {
  /* text-decoration: none; */
}
body {
  min-height: 100vh;
}
form label {
  font-size: 16px;
  margin: 0 1.2em;
  color: rgb(88, 88, 88);
}
@media only screen and (max-height: 600px) {
  .main {
    margin-top: 1rem;
  }
  .policyLinks {
    margin-top: 4rem;
  }
}
</style>
